<template>
  <div>
    <h3>Projects</h3>
    <FormProject
      ref="formProject"
      @save="addProject"
    />
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <TableProjects />
      </v-col>
    </v-row>

    <h3>Theme</h3>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-switch
          v-model="$vuetify.theme.dark"
          @change="saveTheme"
          inset
          label="Dark theme"
          persistent-hint
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormProject from '@/components/FormProject'
import TableProjects from '@/components/TableProjects'
import { mapActions } from 'vuex'

export default {
  components: {
    FormProject,
    TableProjects,
  },
  methods: {
    ...mapActions('projects', { loadProjects: 'loadItems', createProject: 'createItem' }),
    ...mapActions('app', ['setTheme']),
    async addProject (project) {
      await this.createProject(project)
      this.$refs.formProject.clear()
    },
    async saveTheme () {
      const theme = this.$vuetify.theme.dark ? 'DARK' : 'LIGHT'
      await this.setTheme(theme)
    },
  },
  created () {
    this.loadProjects()
  },
}
</script>
