<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
        Add task
      </v-card-title>

      <v-card-text>
        <FormTask
          ref="formTask"
          :date="proposedDate"
          :task="task"
          @save="saveAndClose"
          @save-and-start="saveAndStartAndClose"
          @cancel="close"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import FormTask from './FormTask'

export default {
  components: {
    FormTask,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    proposedDate: {
      default: moment().format(),
      type: String,
    },
    task: {
      type: Object,
    },
  },
  data () {
    return {
      localShow: false,
    }
  },
  methods: {
    ...mapActions('tasks', { createTask: 'createItem' }),
    async saveAndClose (form) {
      await this.saveTask(form)
      this.$emit('input', false)
    },
    async saveAndStartAndClose (form) {
      const newTask = await this.saveTask(form)
      this.$emit('input', false)
      this.$emit('start-task', newTask.id)
    },
    async saveTask (form) {
      const taskDate = form.taskDate || moment().format()
      const task = {
        title: form.title,
        timeAllocated: form.timeAllocated,
        timeSpent: form.timeSpent || 0,
        taskDate,
      }
      if (form.projectId) {
        task.projectId = form.projectId
        task.project = {
          id: form.projectId,
          name: this.$store.state.projects.items[form.projectId].name,
        }
      }
      return await this.createTask(task)
    },
    close () {
      this.$emit('input', false)
    },
  },
  watch: {
    value (value) {
      if (!value) {
        this.$refs.formTask.clear()
        this.$emit('clear-task-copied')
      }
    },
  },
}
</script>
