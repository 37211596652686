<template>
  <div>
    <v-row class="mb-3">
      <v-col cols="11">
        <TotalsRow :tasks="flatendedTasks" />
      </v-col>
      <v-col class="week-select-container py-1" cols="1">
        <v-menu>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :color="$vuetify.theme.dark ? 'white' : 'blue-grey'"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-date-picker
                range
                show-adjacent-months
                first-day-of-week="1"
                color="blue-grey"
                v-model="selectedWeek"
                @click:date="selectWeek"
              ></v-date-picker>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <div class="week">
      <DayColumn
        v-for="day of days" :key="day.day"
        :date="day.date"
        :day="day.day"
        :allocated="totalAllocated(day.tasks)"
        :spent="totalSpent(day.tasks)"
        @add-task="addTask(day.date)"
      >
        <CardTask
          v-for="task in day.tasks" :key="task.id"
          :ref="`cartTask-${task.id}`"
          :task="task"
          :started="startedTaskId"
          @task-started="taskStarted"
          @copy-task="copyTask"
        />
      </DayColumn>
      <PopupAddTask
        v-model="showAddTaskPopup"
        :proposedDate="newTaskDate"
        :task="taskBeingCopied"
        @start-task="startTask"
        @clear-task-copied="taskBeingCopied = null"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DayColumn from '@/components/DayColumn'
import CardTask from '@/components/CardTask'
import PopupAddTask from '@/components/PopupAddTask'
import TotalsRow from '@/components/TotalsRow'

export default {
  components: {
    DayColumn,
    CardTask,
    PopupAddTask,
    TotalsRow,
  },
  data () {
    return {
      showAddTaskPopup: false,
      newTaskDate: null,
      dateFrom: null,
      dateTo: null,
      startedTaskId: null,
      taskBeingCopied: null,
      selectedWeek: [],
    }
  },
  computed: {
    days () {
      const mon = moment(this.dateFrom).startOf('isoWeek')
      const tue = moment(mon).add(1, 'days')
      const wed = moment(mon).add(2, 'days')
      const thu = moment(mon).add(3, 'days')
      const fri = moment(mon).add(4, 'days')
      const sat = moment(mon).add(5, 'days')
      const sun = moment(mon).add(6, 'days')
      const tasks = this.$store.state.tasks.items
      return [
        {
          day: 'MON',
          date: mon.format(),
          tasks: Object.values(tasks).filter(task => mon.isSame(task.taskDate, 'day')),
        },
        {
          day: 'TUE',
          date: tue.format(),
          tasks: Object.values(tasks).filter(task => tue.isSame(task.taskDate, 'day')),
        },
        {
          day: 'WED',
          date: wed.format(),
          tasks: Object.values(tasks).filter(task => wed.isSame(task.taskDate, 'day')),
        },
        {
          day: 'THU',
          date: thu.format(),
          tasks: Object.values(tasks).filter(task => thu.isSame(task.taskDate, 'day')),
        },
        {
          day: 'FRI',
          date: fri.format(),
          tasks: Object.values(tasks).filter(task => fri.isSame(task.taskDate, 'day')),
        },
        {
          day: 'SAT',
          date: sat.format(),
          tasks: Object.values(tasks).filter(task => sat.isSame(task.taskDate, 'day')),
        },
        {
          day: 'SUN',
          date: sun.format(),
          tasks: Object.values(tasks).filter(task => sun.isSame(task.taskDate, 'day')),
        },
      ]
    },
    flatendedTasks () {
      return this.days.map(day => day.tasks).flat()
    },
  },
  methods: {
    ...mapActions('tasks', { loadTasks: 'loadItems' }),
    ...mapActions('projects', { loadProjects: 'loadItems' }),
    totalAllocated (tasks) {
      return tasks.reduce((total, task) => total + task.timeAllocated, 0)
    },
    totalSpent (tasks) {
      return tasks.reduce((total, task) => total + task.timeSpent, 0)
    },
    addTask (date) {
      this.newTaskDate = date
      this.taskBeingCopied = null
      this.showAddTaskPopup = true
    },
    copyTask (task) {
      this.newTaskDate = undefined
      this.taskBeingCopied = task
      this.showAddTaskPopup = true
    },
    taskStarted (id) {
      this.startedTaskId = id
    },
    startTask (id) {
      this.$refs[`cartTask-${id}`][0].startTask()
    },
    async selectWeek (date) {
      const mon = moment(date).startOf('isoWeek').startOf('day')
      const sun = moment(mon).add(6, 'days').endOf('day')
      this.dateFrom = mon.format()
      this.dateTo = sun.format()
      this.selectedWeek = [mon.format('YYYY-MM-DD'), sun.format('YYYY-MM-DD')]
      await this.loadTasks({
        filters: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        },
      })
    },
  },
  async created () {
    this.selectWeek()
    await this.loadProjects()
  },
  async beforeDestroy () {
    if (this.startedTaskId) {
      await this.$refs[`cartTask-${this.startedTaskId}`][0].stopTask()
    }
  },
}
</script>

<style lang="scss" scoped>
.week-select-container {
  background-color: #ECEFF1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.week {
  overflow: auto;
  display: flex;
  flex-direction: row;
}
.theme--dark {
  .week-select-container {
    background-color: #37474F;
  }
}
</style>
