import moment from 'moment'

export const secToTime = sec => {
  let min = `${parseInt(parseInt(sec / 60) % 60)}`
  min = min.length === 1 ? `0${min}` : min
  const h = parseInt(sec / 3600)
  return `${h}:${min}`
}

export const secToTimeDecimal = sec => {
  console.log(sec)
  let min = `${parseInt(parseInt(sec / 60) % 60)}`
  min = min.length === 1 ? `0${min}` : min
  const h = parseInt(sec / 3600)
  const minDec = min / 60
  console.log(minDec)
  const decString = (h + minDec).toFixed(1)
  console.log(decString)
  return decString.endsWith('.0') ? decString.substring(0, decString.length - 2) : decString
}

export const currentTaskTime = sec => {
  const h = parseInt(sec / 3600)
  const m = parseInt((sec - h * 3600) / 60)
  const s = parseInt(sec - h * 3600 - m * 60)
  const mm = (`${m}`).length === 1 ? `0${m}` : m
  const ss = (`${s}`).length === 1 ? `0${s}` : s
  return `${h}:${mm}:${ss}`
}

export const sloDate = dateTime => {
  return moment(dateTime).format('DD.MM.YYYY')
}
