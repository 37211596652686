<template>
  <div v-if="!navbarHidden">
    <NavBarDesktop
      v-if="isDesktop"
    />
    <NavBarMobile
      v-if="isMobile"
    />
  </div>
</template>

<script>
import NavBarDesktop from './NavBarDesktop'
import NavBarMobile from './NavBarMobile'

export default {
  components: {
    NavBarDesktop,
    NavBarMobile,
  },
  computed: {
    navbarHidden () {
      return ['Login', 'Register'].includes(this.$route.name)
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
</script>
