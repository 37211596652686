import api from './base'

export default {
  register (data) {
    return api().post('auth/register', data)
  },
  removeUser (userId) {
    return api().delete(`users/${userId}`)
  },
  login (data) {
    return api().post('auth/login', data)
  },
  logout () {
    return new Promise((resolve, reject) => {
      resolve()
    })
    // return api().delete('private/logout')
  },
}
