import crudMutations from '../crudMutations'
import { loadItemsBuilder, loadItemBuilder, createItemBuilder, updateItemBuilder, deleteItemBuilder } from '../crudFactory'
const apiEndpoint = 'tasks'

export default {
  namespaced: true,

  state: {
    items: {},
    currentTaskStartedTimestamp: null,
    currentTask: null,
  },

  getters: {
    currentTime: (state) => (timeNow) => {
      if (!state.currentTask) return null
      const timeSinceStart = (timeNow - state.currentTaskStartedTimestamp) / 1000
      return parseInt(state.currentTask.timeSpent + timeSinceStart)
    },
  },

  actions: {
    loadItems: loadItemsBuilder({ apiEndpoint }),
    loadItem: loadItemBuilder({ apiEndpoint }),
    createItem: createItemBuilder({ apiEndpoint }),
    updateItem: updateItemBuilder({ apiEndpoint }),
    deleteItem: deleteItemBuilder({ apiEndpoint }),
    setCurrentTask ({ commit }, task) {
      commit('updateCurrentTask', task)
    },
  },

  mutations: {
    ...crudMutations,
    updateCurrentTask (state, task) {
      state.currentTaskStartedTimestamp = task ? new Date().getTime() : null
      state.currentTask = task
    },
  },
}
