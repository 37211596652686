import Vue from 'vue'
import store from '../store/index.js'
import VueRouter from 'vue-router'
import Weekly from '../views/Weekly.vue'
import Daily from '../views/Daily.vue'
import Report from '../views/Report.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Settings from '../views/Settings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter (to, from, next) {
      store.dispatch('auth/signOut')
        .then(() => {
          next({ name: 'Login' })
        })
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/',
    name: 'Weekly',
    component: Weekly,
  },
  {
    path: '/daily',
    name: 'Daily',
    component: Daily,
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (pathRequiresGuest(to)) {
    if (!userIsLoggedIn()) {
      next()
    } else {
      next({ name: 'Daily' })
    }
  } else {
    if (userIsLoggedIn()) {
      next()
    } else {
      next({ name: 'Login', query: { redirectTo: to.path } })
    }
  }
})

export default router

const pathRequiresGuest = path => {
  return path.matched.some(route => route.meta.requiresGuest)
}

const userIsLoggedIn = () => {
  return store.state.auth.jwt
}
