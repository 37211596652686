<template>
  <div class="day">
    <div class="day-header mb-2" :class="{ 'today': dateIsToday }">
      <v-row>
        <v-col class="pl-6">
          <span class="font-weight-bold text-subtitle-2">{{ day }}</span>
          <br>
          <span class="text-caption">{{date | sloDate}}</span>
        </v-col>
        <v-col class="pr-6 text-right">
          <span class="font-weight-bold" :style="{color: timeIsOver ? 'red' : 'green'}">{{spent | secToTime}}</span> / {{allocated | secToTime}}
        </v-col>
      </v-row>
    </div>
    <div class="day-tasks">
      <slot></slot>
    </div>
    <div class="add-task text-center">
      <v-btn
        small
        text
        @click="$emit('add-task')"
      >
        <v-icon
          left
        >
          mdi-plus
        </v-icon>
        Add task
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['day', 'date', 'allocated', 'spent'],
  computed: {
    timeIsOver () {
      return this.spent > this.allocated
    },
    dateIsToday () {
      return moment().isSame(this.date, 'day')
    },
  },
}
</script>

<style lang="scss" scoped>
  .day {
    width: 320px;
    padding: 0 10px;
    height: calc(100vh - 140px);

    .day-header {
      border: 2px solid #B0BEC5;
      min-width: 300px;
      &.today {
        background-color: #ECEFF1;
      }
    }
  }
  .theme--dark {
    .day {
      .day-header {
        &.today {
          background-color: #37474F;
        }
      }
      .day-tasks {
        // max-height: calc(100vh - 230px);
        // overflow: hidden auto;
      }
    }
  }
</style>
