<template>
  <div>
    <div class="menu-bar white--text">
      <div class="top-bar d-flex justify-space-between align-center">
        <h1 class="d-inline">
          time
        </h1>

        <v-btn
          data-cy="navbar-link-toggle-mobile-navbar"
          icon
          color="white"
          @click="showMobileNavbar = !showMobileNavbar"
        >
          <v-icon>{{showMobileNavbar ? 'mdi-close' : 'mdi-menu'}}</v-icon>
        </v-btn>
      </div>

    </div>

    <TransitionExpand>
      <div class="menu-items" v-show="showMobileNavbar">
        <TheNavBarButton
          data-cy="navbar-link-daily"
          name="Daily"
          @click="showMobileNavbar = false"
          :link="{ name: 'Daily' }"
        />
        <TheNavBarButton
          data-cy="navbar-link-weekly"
          name="Weekly"
          @click="showMobileNavbar = false"
          :link="{ name: 'Weekly' }"
        />
        <TheNavBarButton
          data-cy="navbar-link-report"
          name="Report"
          @click="showMobileNavbar = false"
          :link="{ name: 'Report' }"
        />
        <TheNavBarButton
          data-cy="navbar-link-settings"
          name="Settings"
          @click="showMobileNavbar = false"
          :link="{ name: 'Settings' }"
        />
        <TheNavBarButton
          data-cy="navbar-link-logout"
          name="Logout"
          @click="showMobileNavbar = false"
          :link="{ name: 'Logout' }"
        />
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import TheNavBarButton from './TheNavBarButton'
import TransitionExpand from './TransitionExpand'

export default {
  components: {
    TheNavBarButton,
    TransitionExpand,
  },
  data () {
    return {
      showMobileNavbar: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.menu-bar, .menu-items {
  background-color: rgb(96, 125, 139);
}
.top-bar {
  padding: 4px 16px;
}
</style>
