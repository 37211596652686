<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card class="elevation-12">
        <v-toolbar
          class="login-header"
          color="blue-grey"
          dark
          flat
        >
          <v-toolbar-title>Register</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              data-cy="login-email"
              v-model="form.email"
              @keydown.enter="registerUser"
              label="Login"
              name="login"
              prepend-icon="mdi-account"
              type="text"
            />

            <v-text-field
              data-cy="login-password"
              v-model="form.password"
              @keydown.enter="registerUser"
              label="Password"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
            />

            <v-text-field
              data-cy="login-password2"
              v-model="form.password2"
              @keydown.enter="registerUser"
              label="Password again"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            data-cy="login-link"
            text
            :to="{ name: 'Login' }"
          >
            Login
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey"
            @click="registerUser"
            dark
          >register</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        email: null,
        password: null,
        password2: null,
      },
    }
  },
  methods: {
    ...mapActions('auth', ['register']),
    async registerUser () {
      await this.register(this.form)
      this.$router.push({ name: 'Daily' })
    },
  },
}
</script>
