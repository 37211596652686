<template>
  <v-app>
    <TheNavBar />

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TheNavBar from './components/TheNavBar'

export default {
  name: 'App',
  components: {
    TheNavBar,
  },
  data () {
    return {
      localCurrentTime: null,
    }
  },
  computed: {
    ...mapState('app', ['theme', 'title']),
    ...mapState('tasks', ['currentTask']),
    ...mapGetters('tasks', ['currentTime']),
  },
  methods: {
    ...mapActions('app', ['loadTheme']),
    setPageTitle () {
      const prod = process.env.NODE_ENV === 'production'
      let title = this.title || 'time'
      title = prod ? title : `DEV - ${title}`
      document.title = title
    },
  },
  async created () {
    await this.loadTheme()
    this.$vuetify.theme.dark = this.theme === 'DARK'
    this.setPageTitle()
    setInterval(() => {
      const timeNow = new Date().getTime()
      this.localCurrentTime = this.currentTime(timeNow)
    }, 100)
  },
  watch: {
    localCurrentTime () {
      this.setPageTitle()
    },
  },
}
</script>

<style lang="scss">
// scrollbars override
  ::-webkit-scrollbar {
    border-radius: 4px;
    height: 4px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
</style>
