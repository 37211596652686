<template>
  <div :class="isMobile ? 'mobile' : 'desktop'">
    <v-btn
      text
      large
      exact
      class="mx-4 white--text"
      exact-active-class="active"
      :to="link"
      @click="$emit('click')">
      {{name}}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['name', 'link'],
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
</script>

<style lang="scss" scoped>
  .mobile {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .active {
    border-bottom: 2px solid white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &::before {
      opacity: 0 !important;
    }
  }
</style>
