<template>
  <div>
    <v-row class="mb-3">
      <v-col class="title" cols="11">
        {{monthName}} {{year}} <span class="ml-10" v-if="timeTotals">Total hours: {{timeTotals.timeAllocated | secToTimeDecimal}}</span>
      </v-col>
      <v-col class="month-select-container py-1" cols="1">
        <v-menu>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :color="$vuetify.theme.dark ? 'white' : 'blue-grey'"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-date-picker
                v-model="monthAndYear"
                @input="selectMonth"
                type="month"
                color="blue-grey"
              ></v-date-picker>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="loading">
        Loading report ...
      </v-col>
      <v-col v-else>
        <v-row
          v-for="total of projectTotals"
          :key="total.name"
        >
          <v-col cols="6 font-weight-bold">
            {{total.name}}
          </v-col>
          <v-col cols="6">
            {{total.time | secToTimeDecimal}} H
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            v-for="project in reportData.projects"
            :key="project.id"
          >
            <div>
              <span class="font-weight-bold">
                {{project.name}}
              </span>
              <span class="ml-4">
                {{project.timeAllocated | secToTimeDecimal}} H
              </span>
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="width: 250px;">
                      Date
                    </th>
                    <th class="text-left" style="min-width: 400px;">
                      Task name
                    </th>
                    <th class="text-left" style="width: 250px;">
                      Billable hours
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="task in project.tasks"
                    :key="task.id"
                  >
                    <td style="width: 250px;">
                      {{task.taskDate | sloDate}}
                    </td>
                    <td style="min-width: 400px;">
                      {{task.title}}
                    </td>
                    <td style="width: 250px;">
                      {{task.timeAllocated | secToTimeDecimal}}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      monthAndYear: moment().format('YYYY-MM-DD'),
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      loading: false,
      reportData: {},
      timeTotals: null,
    }
  },
  computed: {
    monthName () {
      return moment(this.month, 'MM').format('MMMM')
    },
    projectTotals () {
      const totals = {}
      if (!this.reportData.projects) return []
      for (const project of this.reportData.projects) {
        const name = project.name.split(' - ')[0]
        if (!totals[name]) {
          totals[name] = {
            name: name,
            time: project.timeAllocated,
          }
        } else {
          totals[name].time += project.timeAllocated
        }
      }
      return Object.values(totals)
    },
  },
  methods: {
    ...mapActions('reports', { loadReport: 'loadItems' }),
    async selectMonth () {
      const [year, month] = this.monthAndYear.split('-')
      this.year = year
      this.month = month
      await this.updateReport()
    },
    updateTimeTotals () {
      const [totalSpent, totalAllocated] = this.reportData.projects.reduce((acc, project) => {
        return [
          acc[0] + project.timeSpent,
          acc[1] + project.timeAllocated,
        ]
      }, [0, 0])
      this.timeTotals = {
        timeSpent: totalSpent,
        timeAllocated: totalAllocated,
      }
    },
    async updateReport () {
      this.loading = true
      const response = await this.loadReport({ filters: { year: this.year, month: this.month } })
      this.reportData = Object.values(response)[0]
      this.updateTimeTotals()
      this.loading = false
    },
  },
  async created () {
    await this.updateReport()
  },
}
</script>

<style lang="scss" scoped>
.title {
  background-color: #ECEFF1;
  font-size: 2em;
  font-weight: bold;
  height: 48px;
  line-height: 24px;
  padding-left: 50px;
}
.month-select-container {
  background-color: #ECEFF1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme--dark {
  .title {
    background-color: #37474F;
  }
  .month-select-container {
    background-color: #37474F;
  }
}
</style>
