<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="project in projects"
          :key="project.id"
        >
          <td
            style="cursor: text"
            v-show="projectInEditMode !== project.id"
            @click="startEditing(project)"
          >
            {{ project.name }}
          </td>
          <td
            v-show="projectInEditMode === project.id"
          >
            <form @submit.prevent="saveProject">
              <v-text-field
                data-cy="form-project-name"
                v-model="form.name"
                autofocus
                label="Name"
                @keydown.esc="stopEditing"
                @blur="stopEditing"
              ></v-text-field>
            </form>
          </td>
          <td class="text-right"><ButtonIconDelete @click="removeProject(project.id)" /></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ButtonIconDelete from './ButtonIconDelete'

export default {
  components: {
    ButtonIconDelete,
  },
  data () {
    return {
      projectInEditMode: null,
      form: {},
    }
  },
  computed: {
    ...mapState('projects', { projects: 'items' }),
  },
  methods: {
    ...mapActions('projects', { deleteProject: 'deleteItem', updateProject: 'updateItem' }),
    startEditing (project) {
      this.projectInEditMode = project.id
      this.form = { ...project }
    },
    stopEditing () {
      this.projectInEditMode = null
      this.form = {}
    },
    async saveProject () {
      await this.updateProject(this.form)
      this.stopEditing()
    },
    removeProject (id) {
      this.deleteProject(id)
    },
  },
}
</script>
