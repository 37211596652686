<template>
  <form @submit.prevent="save">
    <v-row>
      <v-col cols="7" md="5" lg="4" xl="3"  offset-lg="1">
        <v-text-field
          data-cy="form-project-name"
          v-model="form.name"
          autofocus
          label="Name"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn type="submit">Save</v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  data () {
    return {
      form: {
        name: '',
      },
    }
  },
  methods: {
    save () {
      this.$emit('save', this.form)
    },
    clear () {
      this.form.name = ''
    },
  },
}
</script>
