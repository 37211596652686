import crudMutations from '../crudMutations'
import { loadItemsBuilder } from '../crudFactory'
const apiEndpoint = 'reports'

export default {
  namespaced: true,

  state: {
    items: {},
  },

  actions: {
    loadItems: loadItemsBuilder({ apiEndpoint }),
  },

  mutations: {
    ...crudMutations,
  },
}
