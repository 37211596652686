<template>
  <form @submit.prevent="save">
    <v-row>
      <v-col>
        <v-text-field
          data-cy="form-task-title"
          v-model="form.title"
          autofocus
          label="Title"
          @keydown.meta.enter="saveAndStart"
          @keydown.ctrl.enter="saveAndStart"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          auto-select-first
          :items="projects"
          label="Project"
          item-text="name"
          item-value="id"
          v-model="form.projectId"
          @keydown.native.meta.enter="saveAndStart"
          @keydown.native.ctrl.enter="saveAndStart"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          data-cy="form-task-allocated-time"
          v-model="form.timeAllocated"
          label="Allocated"
          @keydown.meta.enter="saveAndStart"
          @keydown.ctrl.enter="saveAndStart"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          data-cy="form-task-actual-time"
          v-model="form.timeSpent"
          label="Actual"
          @keydown.meta.enter="saveAndStart"
          @keydown.ctrl.enter="saveAndStart"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DatePicker
          v-model="form.taskDate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn type="button" class="float-right" @click="saveAndStart">Save & start</v-btn>
        <v-btn type="submit" class="float-right mr-3">Save</v-btn>
        <v-btn text type="button" class="float-right mr-3" @click="cancel">Cancel</v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { parseTime } from '@/utils/time'
import { secToTime } from '@/utils/filters'
import DatePicker from './DatePicker'

export default {
  props: ['date', 'task'],
  components: {
    DatePicker,
  },
  data () {
    return {
      form: {
        title: '',
        timeAllocated: '',
        timeSpent: '',
        projectId: null,
        taskDate: this.date,
      },
    }
  },
  computed: {
    projects () {
      return Object.values(this.$store.state.projects.items)
    },
  },
  methods: {
    save () {
      this.parseTimeData()
      this.$emit('save', this.form)
    },
    saveAndStart () {
      this.parseTimeData()
      this.$emit('save-and-start', this.form)
    },
    cancel () {
      this.$emit('cancel')
    },
    clear () {
      this.form.title = ''
      this.form.timeAllocated = ''
      this.form.timeSpent = ''
      this.form.projectId = null
    },
    parseTimeData () {
      this.form.timeSpent = parseTime(this.form.timeSpent)
      this.form.timeAllocated = parseTime(this.form.timeAllocated)
    },
  },
  watch: {
    date (date) {
      this.form.taskDate = this.date
    },
    task: {
      immediate: true,
      handler (task) {
        if (task) {
          this.form.title = task.title
          this.form.timeAllocated = secToTime(task.timeAllocated)
          this.form.timeSpent = ''
          this.form.projectId = task.projectId
        }
      },
    },
  },
}
</script>
