import notify from '@/utils/notify'
import auth from '@/api/auth'

export default {
  namespaced: true,

  state: {
    jwt: localStorage.getItem('jwt'),
    user: localStorage.getItem('user'),
  },

  actions: {
    async signInWithEmailAndPassword ({ commit, dispatch }, loginData) {
      try {
        const { data } = await auth.login(loginData)
        commit('setJwt', data.token)
        const user = data.user
        commit('setUser', user)
        return user
      } catch (error) {
        notify.error('Login failed')
        throw error
      }
    },

    async register ({ commit, dispatch }, registerData) {
      try {
        const { data } = await auth.register(registerData)
        commit('setJwt', data.token)
        const user = data.user
        commit('setUser', user)
        return user
      } catch (error) {
        notify.error('Registration failed')
        throw error
      }
    },

    async removeUser ({ store, dispatch }, userId) {
      try {
        await auth.removeUser(userId)
        if (store.user.id === userId) {
          dispatch('forceSignOut')
        }
        return true
      } catch (error) {
        notify.error('Removing user failed')
        throw error
      }
    },

    async signOut ({ commit }) {
      try {
        await auth.logout()
        commit('setJwt', null)
        commit('setUser', null)
      } catch (error) {
        notify.error('Logout failed')
        throw error
      }
    },

    async forceSignOut ({ commit }) {
      commit('setJwt', null)
      commit('setUser', null)
    },
  },

  mutations: {
    setJwt (state, jwt) {
      jwt ? localStorage.setItem('jwt', jwt) : localStorage.removeItem('jwt')
      state.jwt = jwt
    },
    setUser (state, user) {
      user ? localStorage.setItem('user', user) : localStorage.removeItem('user')
      state.user = user
    },
  },
}
