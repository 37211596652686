<template>
  <v-row class="totals-row" @click="showPerProject = !showPerProject">
    <v-col cols="12" v-if="!showPerProject">
      <span class="font-weight-bold mr-4">
        Total
      </span>
      <span>
        <span
          class="font-weight-bold" :style="{color: spentTotal > allocatedTotal ? 'red' : 'green'}"
        >
          {{spentTotal | secToTime}}
        </span> / {{allocatedTotal | secToTime}}
      </span>
    </v-col>
    <v-col cols="4" sm="3" md="2"
      v-for="totals of totalsPerProject"
      v-else
      :key="totals.projectName"
    >
      <div>
        {{totals.projectName}}
      </div>
      <div>
        <span
          class="font-weight-bold" :style="{color: totals.spent > totals.allocated ? 'red' : 'green'}"
        >
          {{totals.spent | secToTime}}
        </span> / {{totals.allocated | secToTime}}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['tasks'],
  data () {
    return {
      spentTotal: 0,
      allocatedTotal: 0,
      totalsPerProject: [],
      showPerProject: false,
    }
  },
  methods: {
    calculateTotals () {
      this.spentTotal = 0
      this.allocatedTotal = 0
      this.totalsPerProject = []
      this.tasks.forEach(task => {
        this.spentTotal += task.timeSpent
        this.allocatedTotal += task.timeAllocated
        addToProjectTotals(this.totalsPerProject, task)
      })
    },
  },
  watch: {
    tasks: {
      handler: function (val, oldVal) {
        this.calculateTotals()
      },
      deep: true,
    },
  },
}

const addToProjectTotals = (totals, task) => {
  const projectName = task.project?.name || 'Other'
  if (projectInArray(totals, projectName)) {
    totals.forEach(total => {
      if (total.projectName === projectName) {
        total.spent += task.timeSpent
        total.allocated += task.timeAllocated
      }
    })
  } else {
    totals.push({
      projectName,
      spent: task.timeSpent,
      allocated: task.timeAllocated,
    })
  }
}

const projectInArray = (totals, projectName) => {
  return totals.some(total => total.projectName === projectName)
}
</script>

<style lang="scss" scoped>
.totals-row {
  background-color: #ECEFF1;
}
.theme--dark {
  .totals-row {
    background-color: #37474F;
  }
}
</style>
