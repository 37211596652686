<template>
<div>
  <v-card
    class="card mb-2"
    :data-cy="`card-task-${localTask.id}`"
    @contextmenu="showMenu"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-row class="mb-2">
          <v-col class="pb-2">
            <span v-if="!editTask">{{localTask.title}}</span>
            <span v-show="editTask">
              <v-text-field
                label="Title"
                ref="taskTitleInput"
                v-model="localTask.title"
                data-cy="task-title-input"
                hide-details="true"
                @keydown.enter="saveTask"
                @keydown.esc="editTaskOff"
              ></v-text-field>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pb-0 pt-0" :class="{'pt-0': editTask}">
            <span v-if="!editTask">
              {{localTask.taskDate | sloDate}}
            </span>
            <span
              v-if="editTask"
              data-cy="task-date-input-container"
            >
              <DatePicker
                v-model="localTask.taskDate"
              />
            </span>
          </v-col>
          <v-col cols="6" class="pb-0 pt-0" :class="{'pt-0': editTask}">
            <span class="font-weight-bold" v-if="!editTask">
              {{projectName}}
            </span>
            <span
              v-if="editTask"
            >
              <v-autocomplete
                auto-select-first
                clearable
                :items="projects"
                label="Project"
                item-text="name"
                item-value="id"
                v-model="localTask.projectId"
                @keydown.enter="saveTask"
                @keydown.esc="editTaskOff"
              ></v-autocomplete>
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="time pb-2">
            <span class="spent" data-cy="task-time-spent-preview" :style="{color: timeIsOver ? 'red' : 'green'}" v-if="!editTask">
              <v-icon
                class="time-icon"
                :color="timeIsOver ? 'red' : 'green'"
              >
                mdi-clock-outline
              </v-icon>
              {{localTask.timeSpent | secToTime}}
            </span>
            <span v-if="editTask">
              <v-text-field
                class="time-spent"
                label="Spent"
                v-model="localSpent"
                data-cy="task-time-spent-input"
                hide-details="true"
                @keydown.enter="saveTask"
                @keydown.esc="editTaskOff"
              ></v-text-field>
            </span>
             /
            <span class="allocated" data-cy="task-time-allocated-preview" v-if="!editTask">{{localTask.timeAllocated | secToTime}}</span>
            <span v-if="editTask">
              <v-text-field
                class="time-allocated"
                label="Allocated"
                v-model="localAllocated"
                data-cy="task-time-allocated-input"
                hide-details="true"
                @keydown.enter="saveTask"
                @keydown.esc="editTaskOff"
              ></v-text-field>
            </span>
          </v-col>
          <v-col
            data-cy="task-controls"
            class="controls text-right pb-2"
          >
            <ButtonIconStart
              @click="startTask"
              v-if="!taskRunning && !editTask"
            />
            <ButtonIconStop
              @click="stopTask"
              v-if="taskRunning && !editTask"
            />
            <ButtonIconEdit
              @click="editTaskOn"
              v-if="!editTask"
            />
            <ButtonIconSave
              @click="saveTask"
              v-if="editTask"
            />
            <ButtonIconCancel
              @click="editTaskOff"
              v-if="editTask"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <div
      class="time-overlay"
      data-cy="task-time-overlay"
      @click="stopTask"
      v-show="taskRunning"
    >
      <div class="mb-4 mx-2">
        {{localTask.title}}
      </div>
      <div>
        <span class="current-time" :style="{color: timeIsOver ? 'red' : overlayTextColor}">
          <v-icon
            class="time-icon"
            :color="timeIsOver ? 'red' : overlayTextColor"
          >
            mdi-clock-outline
          </v-icon>
          {{localTask.timeSpent | currentTaskTime}}
        </span>
        <span> / {{localTask.timeAllocated | secToTime}}</span>
      </div>

    </div>
  </v-card>

  <v-menu
    v-model="menuShown"
    :position-x="menuX"
    :position-y="menuY"
    absolute
    offset-y
  >
    <v-list>
      <v-list-item @click="$emit('copy-task', localTask)">
        <v-list-item-title>Duplicate</v-list-item-title>
      </v-list-item>
      <v-list-item @click="deleteTask(localTask.id)">
        <v-list-item-title >Delete</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { parseTime } from '@/utils/time'
import { secToTime, currentTaskTime } from '@/utils/filters'
import DatePicker from './DatePicker'
import ButtonIconStart from './ButtonIconStart'
import ButtonIconStop from './ButtonIconStop'
import ButtonIconEdit from './ButtonIconEdit'
import ButtonIconSave from './ButtonIconSave'
import ButtonIconCancel from './ButtonIconCancel'

export default {
  components: {
    DatePicker,
    ButtonIconStart,
    ButtonIconStop,
    ButtonIconEdit,
    ButtonIconSave,
    ButtonIconCancel,
  },
  props: ['task', 'started'],
  data () {
    return {
      menuShown: false,
      menuX: 0,
      menuY: 0,
      localTask: {},
      localSpent: 0,
      localAllocated: 0,
      editTask: false,
      currentTimeInterval: null,
    }
  },
  computed: {
    ...mapGetters('tasks', ['currentTime']),
    taskRunning () {
      return this.$store.state.tasks.currentTask?.id === this.task.id
    },
    timeIsOver () {
      return this.localTask.timeSpent > this.localTask.timeAllocated
    },
    projects () {
      return Object.values(this.$store.state.projects.items)
    },
    projectName () {
      return this.localTask.project?.name || ''
    },
    overlayTextColor () {
      return this.$store.state.app.theme === 'LIGHT' ? 'black' : 'white'
    },
  },
  methods: {
    ...mapActions('app', ['setTitle']),
    ...mapActions('tasks', { deleteTask: 'deleteItem', updateTask: 'updateItem', setCurrentTask: 'setCurrentTask' }),
    async startTask () {
      this.$emit('task-started', this.task.id)
      await this.waitForOldTaskToStop()

      this.setCurrentTask(this.task)
      this.currentTimeInterval = setInterval(() => {
        const timeNow = new Date().getTime()
        const currentTime = this.currentTime(timeNow)
        if (currentTime) {
          this.localTask.timeSpent = currentTime
          this.localSpent = secToTime(currentTime)
          this.setTitle(`${currentTaskTime(currentTime)} - ${this.localTask.title}`)
        }
      }, 100)
    },
    async waitForOldTaskToStop () {
      while (this.$store.state.tasks.currentTask?.id) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    },
    async stopTask () {
      if (this.taskRunning) {
        await this.updateTask({ id: this.localTask.id, timeSpent: this.localTask.timeSpent })
        this.setCurrentTask(null)
        this.setTitle(null)
        clearInterval(this.currentTimeInterval)
      }
    },
    editTaskOn () {
      this.editTask = true
      this.$nextTick(() => {
        this.$refs.taskTitleInput.focus()
      })
    },
    async saveTask () {
      this.localTask.timeSpent = parseTime(this.localSpent)
      this.localTask.timeAllocated = parseTime(this.localAllocated)
      this.localSpent = secToTime(parseTime(this.localSpent))
      this.localAllocated = secToTime(parseTime(this.localAllocated))
      if (this.localTask.projectId) {
        this.localTask.project = {
          id: this.localTask.projectId,
          name: this.$store.state.projects.items[this.localTask.projectId].name,
        }
      } else {
        this.localTask.projectId = null
        this.localTask.project = {}
      }
      await this.updateTask(this.localTask)
      this.editTask = false
    },
    editTaskOff () {
      this.setLocalData()
      this.editTask = false
    },
    setLocalData () {
      this.localTask = { ...this.task }
      this.localSpent = secToTime(this.localTask.timeSpent)
      this.localAllocated = secToTime(this.localTask.timeAllocated)
    },
    showMenu (e) {
      e.preventDefault()
      this.menuShown = false
      this.menuX = e.clientX
      this.menuY = e.clientY
      this.$nextTick(() => {
        this.menuShown = true
      })
    },
  },
  created () {
    this.setLocalData()
  },
  watch: {
    started (started) {
      if (this.task.id !== started && this.taskRunning) {
        this.stopTask()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  min-width: 300px;
}
.backdrop {
  position: absolute;
  top: -65px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}
.time-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255);
  z-index: 100;

  .current-time {
    height: 2em;
    font-size: 1.8em;
    font-weight: bold;
    .time-icon {
      font-size: 1.1em;
      margin-top: -5px;
    }
  }
}
.theme--dark {
  .time-overlay {
    background-color: rgba(0, 0, 0);
  }
}
.time {
  .spent {
    font-weight: bold;
    .time-icon {
      font-size: 1.1em;
      margin-top: -2px;
    }
  }
}
.controls {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.time-spent,
.time-allocated {
  display: inline-block;
  width: 52px;
}
</style>
