<template>
  <v-app-bar
    data-cy="navbar"
    app
    color="blue-grey"
    dark
  >
    <div class="d-flex align-center">
      <h1>
        time
      </h1>
    </div>

    <v-spacer></v-spacer>

    <TheNavBarButton
      data-cy="navbar-link-daily"
      name="Daily"
      :link="{ name: 'Daily' }"
    />
    <TheNavBarButton
      data-cy="navbar-link-weekly"
      name="Weekly"
      :link="{ name: 'Weekly' }"
    />
    <TheNavBarButton
      data-cy="navbar-link-report"
      name="Report"
      :link="{ name: 'Report' }"
    />
    <v-btn
      data-cy="navbar-link-settings"
      icon
      color="white"
      :to="{ name: 'Settings' }"
    >
      <v-icon>mdi-cogs</v-icon>
    </v-btn>
    <v-btn
      data-cy="navbar-link-logout"
      icon
      color="white"
      :to="{ name: 'Logout' }"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>

  </v-app-bar>
</template>

<script>
import TheNavBarButton from './TheNavBarButton'

export default {
  components: {
    TheNavBarButton,
  },
}
</script>
