import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'izitoast/dist/css/iziToast.min.css'
import { secToTime, secToTimeDecimal, sloDate, currentTaskTime } from './utils/filters'

Vue.config.productionTip = false

Vue.filter('secToTime', function (sec) {
  return secToTime(sec)
})
Vue.filter('secToTimeDecimal', function (sec) {
  return secToTimeDecimal(sec)
})
Vue.filter('currentTaskTime', function (sec) {
  return currentTaskTime(sec)
})
Vue.filter('sloDate', function (dateTime) {
  return sloDate(dateTime)
})

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

if (window.Cypress) {
  window.app = app
}
