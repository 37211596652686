
export default {
  namespaced: true,

  state: {
    theme: 'LIGHT',
    title: null,
  },

  actions: {
    loadTheme ({ commit }) {
      let theme = localStorage.getItem('theme')
      if (!theme) {
        theme = getOsTheme()
      }
      commit('updateTheme', theme)
    },
    setTheme ({ commit }, theme) {
      localStorage.setItem('theme', theme)
      commit('updateTheme', theme)
    },
    setTitle ({ commit }, title) {
      commit('updateTitle', title)
    },
  },

  mutations: {
    updateTheme (state, theme) {
      state.theme = theme
    },
    updateTitle (state, title) {
      state.title = title
    },
  },
}

const getOsTheme = () => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'DARK'
  } else {
    return 'LIGHT'
  }
}
