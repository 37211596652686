<template>
  <div>
    <v-row class="mb-3">
      <v-col cols="11">
        <TotalsRow :tasks="tasks" />
      </v-col>
      <v-col class="day-select-container py-1" cols="1">
        <v-menu>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :color="$vuetify.theme.dark ? 'white' : 'blue-grey'"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-date-picker
                show-adjacent-months
                first-day-of-week="1"
                color="blue-grey"
                v-model="selectedDay"
              ></v-date-picker>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="task in tasks" :key="task.id"
        cols="12"
        md="4"
        lg="3"
      >
        <CardTask
          :ref="`cartTask-${task.id}`"
          :task="task"
          :started="startedTaskId"
          @task-started="taskStarted"
          @copy-task="copyTask"
        />
      </v-col>
    </v-row>
    <ButtonAddTask @click="addTask" />
    <PopupAddTask
      v-model="showAddTaskPopup"
      :proposedDate="selectedDay"
      :task="taskBeingCopied"
      @start-task="startTask"
      @clear-task-copied="taskBeingCopied = null"
    />
  </div>
</template>

<script>
import moment from 'moment'
import ButtonAddTask from '@/components/ButtonAddTask'
import PopupAddTask from '@/components/PopupAddTask'
import CardTask from '@/components/CardTask'
import TotalsRow from '@/components/TotalsRow'
import { mapActions } from 'vuex'

export default {
  components: {
    ButtonAddTask,
    PopupAddTask,
    CardTask,
    TotalsRow,
  },
  data () {
    return {
      selectedDay: moment().format('YYYY-MM-DD'),
      showAddTaskPopup: false,
      startedTaskId: null,
      taskBeingCopied: null,
    }
  },
  computed: {
    tasks () {
      const tasks = this.$store.state.tasks.items
      return Object.values(tasks).filter(task => moment(this.selectedDay).isSame(task.taskDate, 'day'))
    },
  },
  methods: {
    ...mapActions('tasks', { loadTasks: 'loadItems' }),
    ...mapActions('projects', { loadProjects: 'loadItems' }),
    taskStarted (id) {
      this.startedTaskId = id
    },
    startTask (id) {
      this.$refs[`cartTask-${id}`][0].startTask()
    },
    addTask () {
      this.taskBeingCopied = null
      this.showAddTaskPopup = true
    },
    copyTask (task) {
      this.newTaskDate = undefined
      this.taskBeingCopied = task
      this.showAddTaskPopup = true
    },
    async selectDay (day) {
      day = moment(day)
      await this.loadTasks({
        filters: {
          dateFrom: day.startOf('day').format(),
          dateTo: day.endOf('day').format(),
        },
      })
    },
  },
  async created () {
    await this.selectDay()
    await this.loadProjects()
  },
  async beforeDestroy () {
    if (this.startedTaskId) {
      await this.$refs[`cartTask-${this.startedTaskId}`][0].stopTask()
    }
  },
  watch: {
    selectedDay (day) {
      this.selectDay(day)
    },
  },
}
</script>

<style lang="scss" scoped>
.day-select-container {
  background-color: #ECEFF1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme--dark {
  .day-select-container {
    background-color: #37474F;
  }
}
</style>
