<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card class="elevation-12">
        <v-toolbar
          class="login-header"
          color="blue-grey"
          dark
          flat
        >
          <v-toolbar-title>Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              data-cy="login-email"
              @keydown.enter="signIn"
              v-model="form.email"
              label="Login"
              name="login"
              prepend-icon="mdi-account"
              type="text"
            />

            <v-text-field
              data-cy="login-password"
              @keydown.enter="signIn"
              v-model="form.password"
              id="password"
              label="Password"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            data-cy="register-link"
            text
            :to="{ name: 'Register' }"
          >
            Register
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey"
            @click="signIn"
            dark
          >Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        email: null,
        password: null,
      },
    }
  },
  methods: {
    ...mapActions('auth', ['signInWithEmailAndPassword']),
    async signIn () {
      await this.signInWithEmailAndPassword(this.form)
      const redirectTo = this.$route.query.redirectTo || { name: 'Daily' }
      this.$router.push(redirectTo)
    },
  },
}
</script>
