
export const parseTime = time => {
  if (`${time}`.includes('.')) return parseHourFraction(time)
  if (`${time}`.includes(':')) return parseMinutes(time)
  if (time < 10) return 3600 * time
  return time * 60
}

const parseHourFraction = time => {
  return 3600 * time
}

const parseMinutes = time => {
  const [hours, min] = time.split(':')
  return 3600 * hours + 60 * min
}
