import iziToast from 'izitoast'

export default {
  error: message => {
    iziToast.error({
      title: 'Error',
      message,
    })
  },
}
