import notify from '@/utils/notify'
import { arrayToObject } from '@/utils/object'

export const loadItemsBuilder = ({ apiEndpoint }) => async ({ commit }, reqData) => {
  try {
    reqData = reqData || {}
    const { default: api } = await import('@/api/' + apiEndpoint)
    const { data } = await api.getAll(reqData)
    const items = arrayToObject(data.data)
    commit('setElements', { type: 'items', elements: items })
    return items
  } catch (error) {
    notify.error('Loading items failed')
    throw error
  }
}

export const loadItemBuilder = ({ apiEndpoint }) => async ({ commit }, id) => {
  try {
    const { default: api } = await import('@/api/' + apiEndpoint)
    const { data } = await api.getById({ id })
    const item = data.data
    commit('setElement', { type: 'items', id, element: item })
    return item
  } catch (error) {
    notify.error('Loading item failed')
    throw error
  }
}

export const createItemBuilder = ({ apiEndpoint }) => async ({ commit }, item) => {
  try {
    const { default: api } = await import('@/api/' + apiEndpoint)
    const { data } = await api.create({ item })
    const itemId = data.data.id
    commit('setElement', { type: 'items', id: itemId, element: { id: itemId, ...item } })
    return { id: itemId, ...item }
  } catch (error) {
    notify.error('Creating item failed')
    throw error
  }
}

export const updateItemBuilder = ({ apiEndpoint }) => async ({ commit }, item) => {
  try {
    const { default: api } = await import('@/api/' + apiEndpoint)
    await api.update({ id: item.id, item })
    commit('updateElement', { type: 'items', id: item.id, element: item })
    return { ...item }
  } catch (error) {
    notify.error('Updating item failed')
    throw error
  }
}

export const deleteItemBuilder = ({ apiEndpoint }) => async ({ commit }, id) => {
  try {
    const { default: api } = await import('@/api/' + apiEndpoint)
    await api.delete({ id })
    commit('removeElement', { type: 'items', id })
    return true
  } catch (error) {
    notify.error('Deleting item failed')
    throw error
  }
}
