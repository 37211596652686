import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import tasks from './modules/tasks'
import projects from './modules/projects'
import reports from './modules/reports'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    tasks,
    projects,
    reports,
  },
})
