<template>
  <v-menu
    v-model="datePickerOpen"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        data-cy="date-input"
        :value="localDateFormatted"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="date = null"
        hide-details="true"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="localDate"
      @change="datePickerOpen = false"
      first-day-of-week="1"
      color="blue-grey"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  props: ['value'],
  data () {
    return {
      datePickerOpen: false,
      localDateFormatted: this.formatDate(this.value),
      localDate: this.value.substring(0, 10),
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
  },
  watch: {
    localDate (date) {
      this.localDateFormatted = this.formatDate(this.localDate)
      this.$emit('input', moment(this.localDate).format())
    },
    value (val) {
      this.localDate = this.value.substring(0, 10)
    },
  },
}
</script>
